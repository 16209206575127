/*!
 * Copyright 2024 - Swiss Data Science Center (SDSC)
 * A partnership between École Polytechnique Fédérale de Lausanne (EPFL) and
 * Eidgenössische Technische Hochschule Zürich (ETHZ).
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License
 */

interface OopsImageProps {
  className?: string;
  id?: string;
}

export default function OopsImage({ className, id }: OopsImageProps) {
  return (
    <svg viewBox="0 0 211.64 54.61" className={className} id={id}>
      <path
        fill="currentColor"
        d="M76.08,16.41h0c1.23-1.09,2.01-2.69,2.01-4.48,0-3.33-2.7-6.03-6.03-6.03-2.89,0-5.31,2.04-5.89,4.75-1.03-.19-2.1-.3-3.19-.3s-2.26.11-3.34.33c-.57-2.73-3-4.78-5.9-4.78-3.33,0-6.03,2.7-6.03,6.03,0,1.83.82,3.47,2.11,4.57-2.51,2.99-4.02,6.83-4.02,11.03,0,9.49,7.69,17.18,17.18,17.18s17.18-7.69,17.18-17.18c0-4.24-1.54-8.12-4.09-11.12ZM56.25,14.28c-.1.1-.22.14-.35.14s-.25-.05-.35-.14l-1.81-1.81-1.81,1.81c-.1.1-.22.14-.35.14s-.25-.05-.35-.14c-.19-.19-.19-.5,0-.69l1.81-1.81-1.81-1.81c-.19-.19-.19-.5,0-.69s.5-.19.69,0l1.81,1.81,1.81-1.81c.19-.19.5-.19.69,0s.19.5,0,.69l-1.81,1.81,1.81,1.81h.02c.19.19.19.5,0,.69ZM63,38.28c-2.55,0-4.61-2.5-4.61-5.58s2.07-5.58,4.61-5.58,4.61,2.5,4.61,5.58-2.07,5.58-4.61,5.58ZM75.16,14.28c-.1.1-.22.14-.35.14s-.25-.05-.35-.14l-1.81-1.81-1.81,1.81c-.1.1-.22.14-.35.14s-.25-.05-.35-.14c-.19-.19-.19-.5,0-.69l1.81-1.81-1.81-1.81c-.19-.19-.19-.5,0-.69s.5-.19.69,0l1.81,1.81,1.81-1.81c.19-.19.5-.19.69,0s.19.5,0,.69l-1.81,1.81,1.81,1.81h.02c.19.19.19.5,0,.69Z"
      />
      <g>
        <path
          fill="currentColor"
          d="M39.48,21.66c0,4.6-.87,8.51-2.6,11.74-1.74,3.23-4.1,5.69-7.08,7.38-2.99,1.7-6.34,2.54-10.06,2.54s-7.11-.85-10.09-2.55c-2.98-1.7-5.33-4.17-7.06-7.39-1.73-3.23-2.59-7.13-2.59-11.72s.86-8.51,2.59-11.74c1.73-3.23,4.08-5.69,7.06-7.38C12.62.85,15.99,0,19.74,0s7.07.85,10.06,2.54c2.99,1.7,5.35,4.16,7.08,7.38,1.74,3.23,2.6,7.14,2.6,11.74ZM30.44,21.66c0-2.98-.44-5.49-1.33-7.54s-2.13-3.6-3.74-4.65-3.49-1.59-5.64-1.59-4.04.53-5.64,1.59c-1.61,1.06-2.85,2.61-3.74,4.65-.89,2.04-1.33,4.56-1.33,7.54s.44,5.49,1.33,7.54c.89,2.04,2.13,3.6,3.74,4.65,1.61,1.06,3.49,1.59,5.64,1.59s4.04-.53,5.64-1.59,2.85-2.61,3.74-4.65c.89-2.05,1.33-4.56,1.33-7.54Z"
        />
        <path
          fill="currentColor"
          d="M101.57,43.36c-3.2,0-5.96-.68-8.29-2.05s-4.12-3.27-5.38-5.72c-1.26-2.45-1.89-5.29-1.89-8.53s.63-6.13,1.89-8.58c1.26-2.45,3.06-4.36,5.38-5.72s5.09-2.05,8.29-2.05,5.96.68,8.29,2.05,4.12,3.27,5.38,5.72c1.26,2.45,1.89,5.31,1.89,8.58s-.63,6.08-1.89,8.53-3.06,4.36-5.38,5.72c-2.33,1.37-5.09,2.05-8.29,2.05ZM101.61,36.57c1.45,0,2.67-.42,3.64-1.25s1.71-1.97,2.21-3.41c.5-1.44.75-3.08.75-4.92s-.25-3.48-.75-4.92-1.24-2.58-2.21-3.42-2.19-1.26-3.64-1.26-2.7.42-3.7,1.26-1.74,1.98-2.24,3.42-.75,3.08-.75,4.92.25,3.48.75,4.92c.5,1.44,1.25,2.58,2.24,3.41s2.23,1.25,3.7,1.25Z"
        />
        <path
          fill="currentColor"
          d="M125.75,54.6V11.12h8.65v5.31h.39c.38-.85.94-1.72,1.68-2.6.73-.89,1.7-1.63,2.88-2.22s2.67-.9,4.44-.9c2.31,0,4.43.6,6.38,1.8,1.95,1.2,3.51,3.01,4.67,5.41s1.75,5.43,1.75,9.05-.57,6.5-1.7,8.93c-1.13,2.42-2.67,4.26-4.61,5.5-1.94,1.24-4.11,1.86-6.52,1.86-1.7,0-3.15-.28-4.33-.84s-2.16-1.27-2.91-2.13-1.33-1.73-1.73-2.6h-.27v16.92h-8.77,0ZM134.34,26.93c0,1.88.26,3.52.78,4.92s1.28,2.49,2.26,3.26c.99.78,2.19,1.16,3.6,1.16s2.63-.39,3.62-1.18c.99-.79,1.74-1.89,2.25-3.29.51-1.41.77-3.03.77-4.87s-.25-3.43-.76-4.82-1.26-2.47-2.24-3.25c-.99-.78-2.2-1.17-3.64-1.17s-2.63.38-3.61,1.13-1.73,1.83-2.25,3.21-.78,3.02-.78,4.9Z"
        />
        <path
          fill="currentColor"
          d="M191.63,20.14l-8.03.49c-.14-.69-.43-1.31-.89-1.86s-1.05-1-1.78-1.34-1.61-.5-2.63-.5c-1.36,0-2.5.28-3.44.85-.93.57-1.4,1.33-1.4,2.28,0,.75.3,1.39.91,1.91.6.52,1.64.94,3.11,1.26l5.72,1.15c3.07.63,5.37,1.65,6.88,3.05,1.51,1.4,2.26,3.24,2.26,5.52,0,2.07-.61,3.89-1.82,5.46s-2.88,2.78-4.98,3.65c-2.11.87-4.53,1.31-7.28,1.31-4.19,0-7.52-.88-10-2.63s-3.93-4.14-4.35-7.15l8.63-.45c.26,1.28.89,2.25,1.89,2.91,1,.67,2.28,1,3.85,1s2.78-.3,3.72-.9c.94-.6,1.42-1.37,1.43-2.32,0-.8-.35-1.45-1.01-1.97-.66-.51-1.67-.91-3.05-1.18l-5.48-1.09c-3.09-.62-5.38-1.69-6.89-3.21-1.5-1.52-2.25-3.47-2.25-5.83,0-2.03.55-3.78,1.66-5.25,1.11-1.47,2.66-2.6,4.67-3.4,2.01-.8,4.37-1.19,7.07-1.19,3.99,0,7.14.84,9.44,2.53s3.64,3.99,4.03,6.9h0Z"
        />
        <path
          fill="currentColor"
          d="M206.7,43.28c-1.36,0-2.53-.48-3.5-1.45s-1.46-2.13-1.44-3.49c0-1.34.47-2.5,1.44-3.46s2.14-1.44,3.5-1.44,2.45.48,3.44,1.44,1.49,2.11,1.5,3.46c0,.91-.25,1.73-.71,2.48s-1.06,1.35-1.8,1.79c-.74.45-1.55.67-2.43.67ZM211.27.58l-.8,29.53h-7.54l-.82-29.53h9.16Z"
        />
      </g>
    </svg>
  );
}
